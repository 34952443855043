<template>
  <ion-page>    
    <ion-content :fullscreen="true" >

        <template v-if="!loading">
            <div class="ios hydrated item-header">
                <div class="item-header-container">
                    <div class="orange-circle" @click="sectionItems">
                        <uil-angle-left size="20px" />
                    </div>
                    <div class="icon-container" style="margin-left: 10px;">
                        <div class="white-circle">
                            <component size="25px" :is="itemIcon"></component>
                        </div>
                    </div>
                    <div class="item-title">
                        <h1>{{ item.name }}</h1>
                        <div class="item-section">- {{ section.name }}</div>
                    </div>
                    <div class="icon-container" style="opacity: 0;">
                        <div class="white-circle">
                            <component size="25px" :is="itemIcon"></component>
                        </div>
                    </div>
                    <div class="orange-circle"  style="opacity: 0;">
                        <uil-angle-left size="25px" />
                    </div>
                </div>

                <template v-if="item.images.length > 0">
                    <ion-grid class="taken-photos">
                        <ion-row class="ion-justify-content-center ion-align-items-start">
                            <ion-col size="4" :key="photo" v-for="(photo, index) in item.images" class="image-col">
                                <div class="notes-image-container" @click="() => showImg(index, 'item')">
                                    <div class="image-container">
                                        <ion-img :src="$store.state.images.images[photo.id].base64" class="item-image"></ion-img>
                                        <uil-search-plus size="20px" class="magnifier" />
                                    </div>
                                    <!-- <template v-if="photo.base64">
                                        <div class="image-container">
                                            <ion-img :src="photo.base64" class="item-image"></ion-img>
                                            <uil-search-plus size="20px" class="magnifier" />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="image-container">
                                            <ion-img :src="photo" class="item-image"></ion-img>
                                            <uil-search-plus size="20px" class="magnifier" />
                                        </div>
                                    </template> -->
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <!-- <img class="item-image" :src="item.images[0].base64">-->

                    <vue-easy-lightbox
                        scrollDisabled
                        escDisabled
                        :visible="visible"
                        :imgs="imgs"
                        :index="index"
                        :loop="true"
                        @hide="handleHide('item')"
                    >
                    </vue-easy-lightbox>
                </template>

                <template v-if="item.description != null">
                    <div class="item-description">{{ item.description }}</div>
                </template>
            </div>

            <vue-easy-lightbox
                scrollDisabled
                escDisabled
                :visible="visibleQuestion"
                :imgs="activeImgQuestions"
                :index="indexQuestion"
                :loop="true"
                @hide="handleHide('question')"
            >
            </vue-easy-lightbox>

            <div class="question-section">
                <div class="section-title">
                    <h2>Questions</h2>
                </div>

                <template v-if="Object.keys(item.questions).length">
                    <ion-card v-for="question in item.questions" :key="question.id" class="question-card">
                        <template v-if="question.conditional == false || (question.conditional != false && conditionalQuestion[question.id])">
                            <template v-if="question.required == 1">
                                <div class="required">Required</div>
                            </template>
                            <ion-card-content>
                                <div class="question-title">{{ question.title }}</div>
                                <template v-if="question.description != null">
                                    <div class="question-description">{{ question.description }}</div>
                                </template>

                                <template v-if="question.images.length > 0">
                                    <div class="question-images">
                                        <!-- <img v-for="image in question.images" :key="image.id" :src="image.base64" @click="() => showImg(index, 'question')">
                                        -->
                                        <ion-row class="ion-justify-content-start ion-align-items-start question-image-row">
                                            <ion-col size="4" v-for="(image, index) in question.images" :key="image.id" :src="image.base64" class="image-col">
                                                <ion-img :src="$store.state.images.images[image.id].base64" class="item-image" @click="() => showImg(index, question)"></ion-img>
                                                <!-- <ion-img :src="image.base64" class="item-image" @click="() => showImg(index, 'question')"></ion-img> -->
                                            </ion-col>
                                        </ion-row>
                                    </div>
                                </template>

                                <template v-if="question.tags.length > 0 && question.tags != null && question.tags[0] != ''">
                                    <div class="question-tag-container">
                                        <div class="question-tag" v-for="tag in question.tags" :key="tag">
                                            {{ tag }}
                                        </div>
                                    </div>
                                </template>

                                <!-- TODO - answer section -->
                                <div class="answer-container">
                                    <div class="answer-card">
                                            <template v-if="question.type == 'text'">
                                                <ion-input @ionBlur="save(question)" inputmode="text" type="text" placeholder="Type your answer" v-model="answersInput[question.id]"  clearInput="true"></ion-input>
                                            </template>
                                            <template v-else-if="question.type == 'multiline_text' || question.type == 'multiline'">
                                                <ion-textarea @ionBlur="save(question)" rows="5" placeholder="Type your answer" v-model="answersInput[question.id]"></ion-textarea>
                                            </template>
                                            <template v-else-if="question.type == 'image'">
                                                <!-- open camera -->
                                                <div class="image-container" v-if="showImage[question.id] && $store.state.images.images[answersInput[question.id]]">
                                                    <ion-img :src="$store.state.images.images[answersInput[question.id]].base64"></ion-img>
                                                    <!-- <ion-img :src="answersInput[question.id].base64"></ion-img> -->
                                                    <div class="bin" @click="clearAnswer(question)"><uil-trash-alt size="25px"/></div>
                                                </div>
                                                <div class="add-media-container" @click="takePicture(question, 'question')">
                                                    <uil-camera class="other" size="25px"/>
                                                    <div class="other">Add Main Photo</div>
                                                </div>
                                            </template>
                                            <template v-else-if="question.type == 'video'">
                                                <div class="video-container">
                                                    <template v-if="answersInput[question.id] != null">
                                                        <video id="playback" controls :src="answersInput[question.id]"></video>
                                                    </template>
                                                    <Video @emitVideoData="videoTest" :question="question"/>
                                                </div>
                                            </template>
                                            <template v-else-if="question.type == 'signature'">
                                                <div class="image-container" v-if="showImage[question.id]">
                                                    <ion-img :src="$store.state.images.images[answersInput[question.id]].base64"></ion-img>
                                                    <!-- <template v-if="answersInput[question.id].base64">
                                                        <ion-img :src="answersInput[question.id].base64"></ion-img>
                                                    </template>
                                                    <template v-else>
                                                        <ion-img :src="answersInput[question.id]"></ion-img>
                                                    </template> -->
                                                    <div class="bin" @click="clearAnswer(question)"><uil-trash-alt size="25px"/></div>
                                                </div>
                                                <ion-button class="sig-btn" @click="openSig(question)">Open Signature</ion-button>
                                                <div v-show="showSigs[question.id]">
                                                    <VueSignaturePad  class="border" width="100%" height="100px" :ref="'signaturePad'+question.id"/>
                                                    <div class="save-container">
                                                        <ion-button class="sig-btn" @click="saveSig(question)">Save</ion-button>
                                                        <ion-button class="sig-btn" @click="clearSig(question)">Clear</ion-button>
                                                        <div class="message">Pressing save will overwrite the current signature</div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else-if="question.type == 'yesno' || question.type == 'checkbox'">
                                                <ion-toggle v-model="answersInput[question.id]" @ionChange="save(question)" :check="answersInput[question.id]" color="primary"></ion-toggle>
                                            </template>
                                            <template v-else-if="question.type == 'number'">
                                                <ion-input @ionBlur="save(question)" inputmode="numeric" type="number" step="1" placeholder="Type your answer" v-model="answersInput[question.id]"
                                                @keypress="numberOnlyValidation($event)"
                                                pattern="[0-9.]*"
                                                ></ion-input>
                                            </template>
                                            <template v-else-if="question.type == 'decimal'">
                                                <ion-input @ionBlur="save(question)" inputmode="decimal" type="number" step="0.1" placeholder="Type your answer" v-model="answersInput[question.id]" ></ion-input>
                                            </template>
                                            <template v-else-if="question.type == 'single_choice'">
                                                <ion-select @ionChange="save(question)" ok-text="Select" cancel-text="Cancel" placeholder="Choose an answer" v-model="answersInput[question.id]">
                                                    <ion-select-option v-for="choice in question.choices" :key="choice" :value="choice">{{ choice }}</ion-select-option>
                                                </ion-select>
                                            </template>
                                            <template v-else-if="question.type == 'multiple_choice'">
                                                <ion-select multiple="true" @ionChange="save(question)" ok-text="Select" cancel-text="Cancel" placeholder="Choose an answer" v-model="answersInput[question.id]">
                                                    <ion-select-option v-for="choice in question.choices" :key="choice" :value="choice">{{ choice }}</ion-select-option>
                                                </ion-select>
                                            </template>
                                            <template v-else-if="question.type == 'rag'">
                                                <ion-radio-group @ionChange="save(question)" v-model="answersInput[question.id]">

                                                    <ion-item lines="none" class="red">
                                                        <ion-label>Red</ion-label>
                                                        <ion-radio mode="md" slot="start" value="red"></ion-radio>
                                                    </ion-item>

                                                    <ion-item lines="none" class="amber">
                                                        <ion-label>Amber</ion-label>
                                                        <ion-radio mode="md" slot="start" value="amber"></ion-radio>
                                                    </ion-item>

                                                    <ion-item lines="none" class="green">
                                                        <ion-label>Green</ion-label>
                                                        <ion-radio mode="md" slot="start" value="green"></ion-radio>
                                                    </ion-item>
                                                </ion-radio-group>
                                            </template>
                                            <template v-else-if="question.type == 'slider'">
                                                <ion-range debounce="500" @ionChange="save(question)" v-model="answersInput[question.id]" ticks="false" :min="question.choices.range_minimum" :max="question.choices.range_maximum" snaps="false" color="secondary">
                                                    <div slot="start">{{ question.choices.range_minimum }}</div>
                                                    <div slot="end">{{ question.choices.range_maximum }}</div>
                                                </ion-range>
                                                <div>Value: <span>{{ answersInput[question.id] }}</span></div>
                                            </template>
                                            <template v-else-if="question.type == 'stepper'">
                                                <ion-range :step="question.choices.range_increment" debounce="500" @ionChange="save(question)" v-model="answersInput[question.id]" ticks="true" :min="question.choices.range_minimum" :max="question.choices.range_maximum" snaps="true" color="secondary">
                                                    <div slot="start">{{ question.choices.range_minimum }}</div>
                                                    <div slot="end">{{ question.choices.range_maximum }}</div>
                                                </ion-range>
                                        
                                                <div>Value: <span>{{ answersInput[question.id] }}</span></div>
                                            </template>
                                            <template v-else-if="question.type == 'star'">
                                                <div class="star-rating">
                                                    <template v-for="x in parseInt(question.choices.range_maximum)" :key="x">
                                                        <!-- <template v-if="answersInput[question.id]"> -->
                                                        <template v-if="x <= answersInput[question.id]">
                                                            <uis-star style="color: #DB9123;" @click="starSelect(x, question)" size="25px" />
                                                        </template>
                                                        <template v-else>
                                                            <uis-star style="color: #CBCACA;" @click="starSelect(x, question)" size="25px" />
                                                        </template>
                                                        <!-- </template> -->
                                                    </template>
                                                    <ion-input type="hidden" v-model="answersInput[question.id]" ></ion-input>
                                                </div>
                                            </template>

                                            
                                    </div>
                                </div>

                                <!-- notes section -->
                                <template v-if="question.notes == '1' || question.notes == 1">
                                    <div class="notes-container">
                                        <div class="notes-title" @click="toggleNotesAccordion(question)">
                                            <div>
                                                <span>Notes</span>
                                                <template v-if="cameraPhotos[question.id].length > 0 || (answerNoteInput[question.id] != null && answerNoteInput[question.id] != '')">
                                                    <uis-check-circle size="20px" class="section-tick" />
                                                </template>
                                            </div>
                                            
                                            <template v-if="notesAccordion[question.id]">
                                                <uil-angle-up size="25px"/> 
                                            </template>
                                            <template v-else>
                                                <uil-angle-down size="25px"/>
                                            </template>
                                        </div>
                                        <ion-card class="notes-card" v-show="notesAccordion[question.id]">
                                            <ion-card-content>
                                
                                                <ion-textarea @ionBlur="save(question)" rows="5" placeholder="Enter answer notes here..." v-model="answerNoteInput[question.id]"></ion-textarea>
                                                <!-- <div class="btn-container">
                                                    <ion-button color="secondary" fill="solid" @click="saveAnswerNote(question)">Save</ion-button>
                                                </div> -->

                                                <div class="add-media-container" @click="takePicture(question, 'notes')">
                                                    <div class="orange-circle">
                                                        <uil-plus size="35px"/>
                                                    </div>
                                                    <uil-camera class="other" size="25px"/>
                                                    <div class="other">Add Notes Photo</div>
                                                </div>
                                                <ion-grid class="taken-photos">
                                                    <ion-row>
                                                        <ion-col size="6" :key="photo" v-for="(photo, index) in cameraPhotos[question.id]">
                                                            <div class="notes-image-container">
                                                                <ion-img :src="$store.state.images.images[photo].base64"></ion-img>
                                                                <!-- <template v-if="photo.base64">
                                                                    <ion-img :src="photo.base64"></ion-img>
                                                                </template>
                                                                <template v-else>
                                                                    <ion-img :src="photo"></ion-img>
                                                                </template> -->

                                                                <div class="bin" @click="clearNoteImage(question, index)"><uil-trash-alt size="25px"/></div>
                                                            </div>
                                                        </ion-col>
                                                    </ion-row>
                                                </ion-grid>
                                            </ion-card-content>
                                        </ion-card>
                                    </div>
                                </template>

                                <!-- <ion-button color="secondary" fill="solid" @click="save(question)">Save</ion-button> -->

                            </ion-card-content>
                        </template>
                    </ion-card>
                </template>
                <template v-else>
                    <div class="no-message">There are no questions for this item.</div>
                </template>
            </div>
        </template>

    </ion-content>

    <ion-footer>
        <ion-toolbar>
            <ion-tabs>
                <ion-router-outlet></ion-router-outlet>
                <ion-tab-bar slot="bottom">
                    <ion-tab-button tab="additem" color="primary" @click="previousOrNextItem('previous')">
                        <uil-angle-left-b size="25px"/>
                        <ion-label>Prev</ion-label>
                    </ion-tab-button>
                    <ion-tab-button tab="notes" @click="sectionItems">
                        <uil-list-ul  size="25px"/>
                        <ion-label>Items</ion-label>
                    </ion-tab-button>
                    <ion-tab-button tab="send" @click="previousOrNextItem('next')">
                        <uil-angle-right-b size="25px"/>
                        <ion-label>Next</ion-label>
                    </ion-tab-button>
                </ion-tab-bar>
            </ion-tabs>
        </ion-toolbar>
    </ion-footer>

  </ion-page>
</template>

<script>
import { 
    IonContent, IonPage, IonTabBar, IonButton, IonProgressBar, IonImg, 
    IonLabel, IonItem, IonCardContent, IonToolbar, IonFooter, IonTabButton, 
    IonTabs, IonSelect, IonRadio, IonRadioGroup, IonRange, IonSelectOption, 
    IonCard, IonTextarea, IonGrid, IonRow, IonToggle, IonCol, IonInput, modalController, IonRouterOutlet
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import { UilAngleLeft, UilAngleLeftB, UilAngleRightB, UilListUl, UilPlus,UilCamera, UilVideo, UilAngleUp, UilAngleDown, UilTrashAlt, UilSearchPlus } from '@iconscout/vue-unicons'
import { UisStar, UisCheckCircle } from '@iconscout/vue-unicons-solid'
import {defineAsyncComponent} from "vue";

import { Camera, CameraResultType, CameraSource, CameraDirection } from "@capacitor/camera";
import VueEasyLightbox from 'vue-easy-lightbox';

import LoadingModal from '../modals/Loading.vue';

import Video from '../components/Video.vue';

import imageCompression from 'browser-image-compression';

export default {
    name: 'Home',
    components: {
        IonContent, IonPage, IonTabBar, IonButton, IonProgressBar, IonImg, 
        IonLabel, IonItem, IonCardContent, IonToolbar, IonFooter, IonTabButton, 
        IonTabs, IonSelect, IonRadio, IonRadioGroup, IonRange, IonSelectOption, 
        IonCard, IonTextarea, IonGrid, IonRow, IonToggle, IonCol, IonInput, IonRouterOutlet,
        UilAngleLeftB, UilAngleRightB, UilListUl, UilPlus, UilCamera, UilVideo, UilAngleUp, UilAngleDown,
        UisStar, UilTrashAlt, UilSearchPlus,
        VueEasyLightbox, Video, UisCheckCircle, UilAngleLeft
    },
    data () {
        return {
            loading : true,
            job_id : this.$route.params.id,
            job : null,
            section_id : this.$route.params.section,
            section : {
                items : []
            },
            item_id : this.$route.params.item,
            items : [],
            item : null,
            answerNoteInput : {},
            answersInput: {},
            notesAccordion : {},
            showSigs : {},
            showImage : {},
            activeQuestion : null,
            cameraPhotos: {},
            sig_images : {},
            constraints : {
                audio: true,
                video: {
                    width: {
                        min: 640,
                        ideal: 1280,
                        max: 1280
                    },
                    height: {
                        min: 480,
                        ideal: 720,
                        max: 720
                    },
                    framerate: 24,
                    facingMode: "environment" ,
                    aspectRatio: { exact: 1 }
                }
            },
            imgs: [],
            imgsQuestion: {},
            activeImgQuestions: [],
            index : 0,
            indexQuestion : 0,
            visible : false,
            visibleQuestion : false,
            loadingModal: null,
            conditionalQuestion : {}
        }
    },
    setup() {

    },
    methods: {
        getSectionOld(job, id) {
            if(id != undefined){
                for(let i = 0; i < job.survey.sections.length; i++){
                    let current = job.survey.sections[i];
                    if(current.id == id){
                        return current
                    }
                }
            }
            return false;
        },
        getItemParamCheck(){
            this.item = this.getItem;

            // todo - back for if this.getItem does not work
        },
        getItemOld(section, id){
            if(id != undefined){
                for(let i = 0; i < section.items.length; i++){
                    let current = section.items[i];
                    if(current.id == id){
                        return current
                    }
                }
            }
            return false;
        },
        getNewItemIndex(id, type){
            let keys = Object.keys(this.items);
            let index = keys.indexOf(id);
            let length = keys.length;
            if(index > -1){
                if(type == "previous"){
                    if(index == 0){
                        index = length - 1;
                    } else {
                        index--;
                    }
                } else {
                    // next
                    if(index == length - 1){
                        index = 0;
                    } else {
                        index++;
                    }
                }
                return this.items[keys[index]];

            } else {
                return false;
            }
        },
        async sectionItems(){
            this.loading = true;

            await this.loadingTriggered();
            await this.$store.dispatch("brightchecker/saveJob", this.job);
            await this.closeModal();

            this.$router.push({ path: '/job/'+this.job_id+'/section/'+this.section_id })
        },
        async previousOrNextItem(type){
            let new_item_id = this.getNewItemIndex(this.item_id, type);
            if(new_item_id != false){
                await this.loadingTriggered();
                await this.$store.dispatch("brightchecker/saveJob", this.job);
                await this.closeModal();
                this.$router.push({ 'path' : '/job/'+this.job_id+'/section/'+this.section_id+'/item/'+ new_item_id })
            }
        },
        async save(question){
            question.answer_note.text = this.answerNoteInput[question.id];

            if(this.cameraPhotos[question.id].length > 0){
                question.answer_note.image = [];
                question.answer_note.image = this.cameraPhotos[question.id];
            }
            question.answer.answer = this.answersInput[question.id];
            question.answer.date_answered = new Date().getTime();
            question.answer.answered_by = this.user.id;

            this.conditionalCheck(question);

            this.$store.commit("brightchecker/SET_CURRENT_ITEM", this.item);
        },
        async videoTest(data,question){
            question.answer.answer = data;
            this.saveVideo(question)
        },
        saveVideo(question){


            let video = question.answer.answer;
            if(video != null){
                video = video.replace(",opus", "")
            }
            this.answersInput[question.id] = video

            this.$store.dispatch("brightchecker/saveJob", this.job);
            
            // let save = document.querySelector("#save");
            // save.style.display = "none"
        },
        toggleNotesAccordion(question) {
            if(this.notesAccordion[question.id]){
                this.notesAccordion[question.id] = false;
            } else {
                this.notesAccordion[question.id] = true;
            }
        },
        async starSelect(index, question){
            this.answersInput[question.id] = index;
            this.save(question)
        },
        openSig(question){
            this.showSigs[question.id] = true;
            this.$nextTick(function () {
                this.$refs['signaturePad' + question.id].resizeCanvas();
            });
        },
        clearIfImage(toCheck){
            let imageKeys = Object.keys(this.$store.state.images.images);
            if(imageKeys.includes(toCheck)){
                // need to clear image from images store as well
                this.$store.commit("images/DELETE_IMAGE", toCheck);
            }
        },
        async saveSig(question){
            const { data } = this.$refs['signaturePad' + question.id].saveSignature();
            if(data != undefined){
                let savedFileImage = { "base64" : data, "name" : 'signaturePad' + question.id, "job_id" : this.getJob.id }
                this.$store.dispatch("images/saveImage", savedFileImage).then(id => {
                    question.answer.answer = id.toString();
                    this.showImage[question.id] = true;
                    this.answersInput[question.id] = id.toString();
                    this.save(question)
                })
            }
        },
        clearAnswer(question){
            this.clearIfImage(this.answersInput[question.id]);
            this.answersInput[question.id] = "";
            this.showImage[question.id] = false;
        },
        clearSig(question){
            this.$refs['signaturePad' + question.id].clearSignature();
            this.clearIfImage(question.answer.answer)
        },
        async compressFileandGetBase64(imageFile, question, type){
            let vm = this;
            const options = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 800,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(imageFile, options);

            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);

                reader.onloadend = () => {
                    let base64 = reader.result;
                    const fileName = new Date().getTime() + '.jpeg';
                    const savedFileImage = {
                        "name" : fileName,
                        "base64" : base64,
                        "job_id" : vm.getJob.id
                    };
                    if(type == "notes"){
                        vm.$store.dispatch("images/saveImage", savedFileImage).then(id => {
                            if(id != undefined){
                                vm.cameraPhotos[question.id].push(id.toString());
                                vm.save(question)
                            }
                        });
                    } else if (type == "question"){
                        vm.$store.dispatch("images/saveImage", savedFileImage).then(id => {
                            vm.answersInput[question.id] = id.toString();
                            vm.showImage[question.id] = true;
                            vm.save(question)
                        })
                    }
                };

        },
        async takePicture(question, type) {
            this.activeQuestion = question;
            // let vm = this;

            try {
                const image = await Camera.getPhoto({
                    // resultType: CameraResultType.Uri,
                    resultType: CameraResultType.Base64,
                    source: CameraSource.Photos,
                    quality: 40,
                    allowEditing: true,
                    correctOrientation : true,
                    height: 800,
                    width: 800,
                    saveToGallery : true,
                    direction : CameraDirection.Rear
                })

                let base64 = "data:image/jpeg;base64," + image.base64String;
                fetch(base64)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "image",{ type: "image/jpeg" })
                    this.compressFileandGetBase64(file, question, type)
                })



                // const imgElement = document.createElement("img");
                // imgElement.src = base64;
                // const canvas = document.createElement("canvas");
                // let ctx = canvas.getContext("2d");

                // imgElement.onload = function () {
                //     let question = vm.activeQuestion;

                //     // set size proportional to image
                //     let ratio =  1000 / imgElement.width;
                //     canvas.height = imgElement.height * ratio;
                //     canvas.width = 1000;

                //     // step 1 - resize to 50%
                //     let oc = document.createElement('canvas'),
                //         octx = oc.getContext('2d');

                //     oc.width = imgElement.width * 0.5;
                //     oc.height = imgElement.height * 0.5;
                //     octx.drawImage(imgElement, 0, 0, oc.width, oc.height);


                //     // step 3, resize to final size
                //     ctx.drawImage(oc, 0, 0, oc.width, oc.height,
                //     0, 0, canvas.width, canvas.height);

                //     const srcEndcoded = ctx.canvas.toDataURL("image/jpeg", 0.5);

                //     const fileName = new Date().getTime() + '.jpeg';
                //     const savedFileImage = {
                //         "name" : fileName,
                //         "base64" : srcEndcoded,
                //         "job_id" : vm.getJob.id
                //     };
                //     if(type == "notes"){
                //         vm.$store.dispatch("images/saveImage", savedFileImage).then(id => {
                //             if(id != undefined){
                //                 vm.cameraPhotos[question.id].push(id.toString());
                //                 vm.save(question)
                //             }
                //         });
                //     } else if (type == "question"){
                //         vm.$store.dispatch("images/saveImage", savedFileImage).then(id => {
                //             vm.answersInput[question.id] = id.toString();
                //             vm.showImage[question.id] = true;
                //             vm.save(question)
                //         })
                //     }
                //     // vm.save(question)
                // }
            } catch (e) {
                console.log("error", e);
            }
        },
        async pageSetup(){
            this.job = this.getJob;
            this.section = this.getSection;
            this.items = this.section.items;
            this.getItemParamCheck();
            this.imgs = [];
            this.imgsQuestion = {};

            if(this.item.images.length > 0){
                for(let x = 0; x < this.item.images.length; x++){
                    this.imgs.push(this.$store.state.images.images[this.item.images[x].id].base64);
                }
            }

            // load already prefilled data in
            for(let question_prop in this.item.questions){
            // for(let i = 0; i < this.item.questions.length; i++){
                let current = this.item.questions[question_prop];
                this.cameraPhotos[current.id] = [];
                this.answersInput[current.id] = null;
                // TODO - load all other questions types
                if((current.type == "yesno" || current.type == "checkbox") && current.answer.answer == null){
                    this.answersInput[current.id] = false;

                } else if(current.type == "video" && current.answer.answer != null){
                    let video;
                    if(current.answer.answer.base64){
                        video = current.answer.answer.base64;
                    } else {
                        video = current.answer.answer;
                    }

                    video = video.replace(",opus", "")
                    this.answersInput[current.id] = video;
                } else if(current.type == "signature" || current.type == "image"){
                    this.showImage[current.id] = true;
                    if(current.answer.answer.id){
                        this.answersInput[current.id] = current.answer.answer.id;
                    } else {
                        this.answersInput[current.id] = current.answer.answer;
                    }
                } else if(current.answer.answer != null){
                    this.answersInput[current.id] = current.answer.answer;
                    this.showImage[current.id] = false;
                }

                // answer note fields
                if(current.answer_note.text != null){
                    this.answerNoteInput[current.id] = current.answer_note.text;
                }

                if(current.answer_note.image && current.answer_note.image.length > 0){
                    for(let y = 0; y < current.answer_note.image.length; y++){
                        if(current.answer_note.image[y].id){
                            this.cameraPhotos[current.id].push(current.answer_note.image[y].id)
                        } else {
                            this.cameraPhotos[current.id].push(current.answer_note.image[y])
                        }
                    
                        // this.imgs.push(this.$store.state.images.images[this.item.images[x].id].base64);
                    }
                }

                for(let z = 0; z < current.images.length; z++){
                    if(!Array.isArray(this.imgsQuestion[current.id])){
                        this.imgsQuestion[current.id] = [];
                    }
                    this.imgsQuestion[current.id].push(this.$store.state.images.images[current.images[z].id].base64);
                    // this.imgsQuestion.push(current.images[z].id);
                }
            }


        },
        async clearNoteImage(question, index){
            this.clearIfImage(this.cameraPhotos[question.id][index]);
            this.cameraPhotos[question.id].splice(index, 1);
            this.save(question)
        },
        showImg(index, type) {
            if(type == "item"){
                this.index = index
                this.visible = true
            } else {
                this.indexQuestion = index
                this.activeImgQuestions = this.imgsQuestion[type.id]
                this.visibleQuestion = true
            }
        },
        handleHide(type) {
            if(type == "item"){
                this.visible = false
            } else {
                this.visibleQuestion = false
            }
        },
        async loadingTriggered(){
            this.loadingModal = await modalController
            .create({
                component: LoadingModal,
                cssClass: 'loading-modal',
            })
            return this.loadingModal.present();
        },
        async closeModal(){
            this.loadingModal.dismiss();
        },
        conditionalCheck(question){
            let answerToCheck = question.answer.answer;

            for(let i = 0; i < this.item.questions.length; i++){
                let current = this.item.questions[i];
                let condition = current.conditional;
                if(current.id != question.id && condition){
                    let answer = condition[2] == "yes" ? true : condition[2] == "no" ? false : condition[2];

                    if(question.id == condition[0]){
                        this.conditionalQuestion[current.id] = false;
                        switch(condition[1]){
                            case "=":
                                if(answerToCheck == answer){
                                    this.conditionalQuestion[current.id] = true;
                                }
                                break;

                            case "!=":
                                if(answerToCheck != answer){
                                    this.conditionalQuestion[current.id] = true;
                                }
                                break;

                            case "<":
                                if(answer < answerToCheck){
                                    this.conditionalQuestion[current.id] = true;
                                }
                                break;

                            case ">":
                                if(answer > answerToCheck){
                                    this.conditionalQuestion[current.id] = true;
                                }
                                break;
                        }
                    }

                }
                console.log(current.conditional, this.conditionalQuestion[current.id])
            }
            console.log("conidtions", this.conditionalQuestion)
            //question.conditional == false || (question.conditional != false && conditionalQuestion[question.id])

        },
        backPage(){
            this.$router.back()
        },
        numberOnlyValidation($event){
            if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode))) {
                return true
            } else {
                $event.preventDefault();
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            getJob: 'brightchecker/current_job',
            getJobs: 'brightchecker/jobs',
            getItem: 'brightchecker/current_item',
            getSection: 'brightchecker/current_section',
            getImages: 'images/images'
        }),
        itemIcon() {
            let icon = this.item.icon_import;
            icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
            return defineAsyncComponent(() => import('@iconscout/vue-unicons/icons/'+icon + '.vue'))
        }
    },
    mounted() {
        // this.pageSetup();
    },
    beforeUpdate() {
        this.loading = true;
    },
    async updated() {
        // this.job_id = this.$route.params.id;
        // this.job = this.getJob;

        // let section_update = this.getSection;
        // console.log(this.getSection);

        // if(section_update != false){
        //     this.section_id = this.$route.params.section
        //     this.section = section_update
        //     this.items = this.section.items;
        // }

        // this.getItemParamCheck();
        // let item_update = this.item;

        // if(item_update != false){
        //     this.item = item_update;
        //     this.item_id = this.$route.params.item
        // }
        this.pageSetup();

        this.loading = false;
    },
    
}
</script>

<style scoped lang="scss">
    h1, h2 {
        text-align: center;
        margin-top: 0px;
        font-size: 20px;
        font-weight: 800;
        line-height: 30px;
        color: #373636;
    }

    .item-header {
        margin-top: 80px;
        padding: 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .item-header-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            h1 {
                margin-bottom: 0px;
            }
            .item-section {
                font-family: 'Raleway', sans-serif;
                font-size: 14px;
                font-weight: 800;
                text-align: center;
                color: #373636;
            }

            .icon-container {
                .white-circle {
                    background-color: #ffffff;
                    padding: 5px;
                    height: 50px;
                    width: 50px;
                    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #DB9123;
                }
            }
            .orange-circle {
                color: black;
            }
        }
        .item-description {
            padding: 5px 20px 0px 20px;
            color: #373636;
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            margin-top: 20px;
        }
    }

    .question-section {
        padding: 0 20px;
        .section-title {
            margin-top: 20px;
        }

        .question-card {
            border-radius: 0px;
            margin-bottom: 20px;
            --background: #ffffff;
            position: relative;

            .required {
                background-color: #DB9123;
                padding: 10px;
                border-bottom-left-radius: 15px;
                top: 0px;
                right: 0px;
                width: fit-content;
                position: absolute;
                color: #373636;
                font-size: 12px;
                font-weight: 800;
                text-transform: uppercase;
                font-family: 'Raleway', sans-serif;
            }

            .question-title {
                font-size: 14px;
                font-weight: 800;
                line-height: 16px;
                text-transform: uppercase;
                width: calc(100% - 120px);
                font-family: 'Raleway', sans-serif;
                margin-bottom: 20px;
            }
            .question-description {
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: 800;
            }
            .question-images {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-content: stretch;
                align-items: center;

                img {
                    width: 30%;
                }
            }
            .question-tag-container {
                display: flex;
                margin: 10px 0px;
                .question-tag {
                    background-color: #CBCACA;
                    border-radius: 50px;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                    padding: 0px 8px;
                    margin: 5px;
                    color: #373636;
                    line-height: 21px;
                    font-size: 12px;
                    font-weight: 600;
                    &:first-child {
                        margin-left: 0px;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
            .notes-container {
                .notes-title {
                    font-size: 14px;
                    font-weight: 800;
                    font-family: 'Raleway', sans-serif;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span {
                            margin-right: 5px;
                        }
                    }
                }

                .notes-card {
                    border-radius: 0px;
                    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
                    margin: 0px 0px;
                    background-color: #ffffff;
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    ion-card-content {
                        padding: 10px;
                    }
                    .answer-note {
                        font-size: 14px;
                        font-weight: 400;
                    }
                    .btn-container {
                        text-align: right;
                        margin-top: 5px;
                    }
                    ion-button {
                        --padding-bottom: 3px;
                        --padding-top: 3px;
                        --padding-start: 5px;
                        --padding-end: 5px;
                        height: 30px;
                    }
                    ion-textarea {
                        font-size: 12px;
                    }
                }
            }
            .answer-container {
                margin-bottom: 10px;
                font-size: 12px;
                .answer-card {
                    // border-radius: 0px;
                    // box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
                    margin: 0px 0px;
                    // background-color: #ffffff;
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    ion-card-content {
                        padding: 10px;
                    }
                    ion-input, ion-textarea {
                        font-size: 12px;
                    }
                    ion-select {
                        background: #F5F5F5;
                    }

                    .red {
                        --background: #ffffff;
                        ion-label {
                            --color: #B41F1F;
                        }
                        ion-radio {
                            --color: #B41F1F;
                            --color-checked: #B41F1F;
                        }
                    }
                    .amber {
                        --background: #ffffff;
                        ion-label {
                            --color: #F7B900;
                        }
                        ion-radio {
                            --color: #F7B900;
                            --color-checked: #F7B900;
                        }
                    }
                    .green {
                        --background: #ffffff;
                        ion-label {
                            --color: #1D9F67;
                        }
                        ion-radio {
                            --color: #1D9F67;
                            --color-checked: #1D9F67;
                        }
                    }
                    .star-rating {
                        svg {
                            color: #CBCACA;
                        }
                    }
                    .sig-btn {
                        --padding-start: 5px;
                        --padding-end: 5px;
                        height: 30px;
                        margin-left: 0px;
                    }
                    .border {
                        border: 1px dashed #a5a5a5;
                    }
                    .image-container {
                        position: relative;
                        margin-bottom: 5px;
                        .bin {
                            position: absolute;
                            top: 0px;
                            right: 0px;
                            color: #DB9123;
                            background-color: #ffffff;
                        }
                    }
                    .save-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-top: 5px;
                        ion-button {
                            margin-right: 10px;
                            margin-top: 0px;
                        }
                        .message {
                            font-size: 10px;
                        }
                    }
                }
            }
            .add-media-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 10px;
                > * {
                    margin-right: 10px;
                }
                .orange-circle {
                    padding: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .other {
                    color: #373636;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }

    ion-toolbar {
        --min-height: 50px;
    }

    ion-tab-button {
        svg {
            color: #DB9123;
        }
        --background: #172943;
        color: #ffffff;
    }

    .no-message {
        text-align: center;
        margin-top: 10px;
        padding: 0px 10px;
    }

    ion-textarea {
        --background: #F5F5F5;
        --padding-start: 5px;
        --padding-end: 5px;
        --padding-top: 5px;
        --padding-bottom: 5px;
    }

    ion-input {
        --background: #F5F5F5;
        --padding-start: 5px;
        --padding-end: 5px;
        --padding-top: 5px;
        --padding-bottom: 5px;
    }

    .video-container {
        video {
            width: 100%;
            margin-bottom: 20px;
        }
        #controls {
            display: flex;
            button {
                background-color: #DB9123;
                padding: 10px 20px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 800;
                line-height: 16px;
                margin-right: 5px;
                &#stop {
                    background-color: #172943;
                }
            }     
        }   
    }

    .notes-image-container {
        position: relative;
        cursor: pointer;
        margin-bottom: 20px;
        margin-top: 10px;
        .bin {
            position: absolute;
            top: 0px;
            right: 0px;
            color: #DB9123;
            background-color: #ffffff;
        }
    }

    .taken-photos .image-container {
        position: relative;
        width: 100px;
        height: 100px;
        margin: auto;
        .magnifier {
            position: absolute;
            bottom: 0px;
            right: 0px;
            color: #DB9123;
            background-color: #172943c4;
            padding: 2px;
            border-top-left-radius: 5px;
        }
    }

    .image-col {
        height: 100px;
        width: 100px!important;
        max-width: 100px !important;
        max-height: 100px !important;
        margin: 5px;
    }

    #stop {
        color: #ffffff;
    }

    .item-image {
        margin: auto;
        margin-bottom: 10px;
        width: 100px;
        height: 100px;
        object-fit: cover;
    }

    .section-tick {
        fill: #1D9F67;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50%;
    }

    // .question-image-row ion-col:first-child {
    //     padding-left: 0px;
    // }
    // .question-image-row ion-col:last-child {
    //     padding-right: 0px;
    // }

    // .question-image-row ion-col ion-img {
    //     height: 100px;
    //     width: 100px;
    // }

</style>